<template>
  <location-suggestion
    :icon="icon"
    :subtitle="labels.subtitle"
    :title="labels.title"
    @click="$emit('click', props)" />
</template>

<script lang="ts" setup>
import type { Maybe } from '~/domains/graphql';
import { SearchDestinationResultType } from '~/domains/graphql';
import type { IconName } from '~/domains/ux/atoms/AiIcon/types';

export type Props = {
  city?: Maybe<string>;
  country?: Maybe<string>;
  hotel?: Maybe<{ id?: Maybe<string>; name?: Maybe<string> }>;
  search?: Maybe<string>;
  type?: Maybe<SearchDestinationResultType>;
};
const props = defineProps<Props>();

type Emits = {
  (event: 'click', value: Props): void;
};
defineEmits<Emits>();

const { t } = useI18n();

const icon = computed<IconName>(() => {
  switch (props.type) {
    case SearchDestinationResultType.Country:
      return 'globe';

    case SearchDestinationResultType.City:
      return 'pin-outline';

    case SearchDestinationResultType.Hotel:
    default:
      return 'hotel';
  }
});

const labels = computed(() => {
  switch (props.type) {
    case SearchDestinationResultType.Country:
      return {
        subtitle: t('search.filter.destination.country'),
        title: props.country as string,
      };

    case SearchDestinationResultType.City:
      return {
        subtitle: t('search.filter.destination.city', {
          country: props.country,
        }),
        title: props.city as string,
      };

    case SearchDestinationResultType.Hotel:
    default:
      return {
        subtitle: t('search.filter.destination.hotel', {
          city: props.city,
          country: props.country,
        }),
        title: props.hotel?.name as string,
      };
  }
});
</script>
