<template>
  <location-autocomplete
    v-model:search="search"
    v-model:suggestions="suggestions"
    :disabled="disabled"
    :errors="errors"
    :hide-suggestions="hideSuggestions"
    :inverse="inverse"
    :label="$t('search.filter.label.destination')"
    :message="inputHint"
    :placeholder="$t('search.filter.value.allDestination')"
    :suggestion-component="DestinationSuggestion"
    :suggestion-empty-helper="
      $t('search.filter.destination.emptyResultsHelper')
    "
    :suggestion-empty-title="$t('search.filter.destination.emptyResults')"
    :suggestion-helper="$t('search.filter.destination.helper')"
    :data-testid="$attrs['data-testid']"
    @focus="onFocus"
    @clear="onClear"
    @debounced-input="onDebounceInput"
    @suggestion-picked="onSuggestionPicked"
    @keydown.enter="$emit('keydown.enter')" />
</template>

<script lang="ts" setup>
import type {
  SearchDestination as GQLSearchDestination,
  SearchDestinationLocalization,
} from '~/domains/graphql';
import {
  SearchDestinationResultType,
  useSearchDestinationQuery,
} from '~/domains/graphql';
import type { SearchDestination } from '~/domains/search';
import { useLayoutStore } from '~/domains/ux';
import type { AiSuggestion } from '~/domains/ux/molecules/AiInput/interfaces';

import DestinationSuggestion from './DestinationSuggestion.vue';

type Props = {
  modelValue: SearchDestination;
  disabled?: boolean;
  errors?: string[];
  hideSuggestions?: boolean;
  inputHint?: string;
  inverse?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  errors: undefined,
  hideSuggestions: false,
  inputHint: '',
  inverse: false,
});

type Emits = {
  (event: 'update:modelValue', value: SearchDestination): void;
  (event: 'destinations-fetched', value: GQLSearchDestination[]): void;
  (event: 'keydown.enter'): void;
};
const emits = defineEmits<Emits>();

const layoutStore = useLayoutStore();

const search = computed({
  get() {
    return props.modelValue;
  },
  set(value: SearchDestination) {
    emits('update:modelValue', value);
  },
});

const { onResult, refetch, result, start, stop } = useSearchDestinationQuery(
  {
    search: search.value.text as string,
  },
  {
    fetchPolicy: 'no-cache',
  },
);

onResult(() => {
  onDestinationsFetched(
    result.value?.searchDestination?.destinations as GQLSearchDestination[],
  );
});

const onDestinationsFetched = (destinations: GQLSearchDestination[] = []) => {
  emits('destinations-fetched', destinations);

  if (process.client) {
    layoutStore.header.queryParameterLocalization = (route, language) => {
      if (typeof route.query?.destination !== 'string') return;

      const searchDestination = JSON.parse(
        route.query.destination,
      ) as SearchDestination;
      const destinationFieldMapping: Record<
        SearchDestinationResultType,
        string | undefined
      > = {
        [SearchDestinationResultType.City]: 'city',
        [SearchDestinationResultType.Country]: 'country',
        [SearchDestinationResultType.Hotel]: undefined,
      };
      const destinationField = (destinationFieldMapping[
        searchDestination.type!
      ] ?? undefined) as keyof SearchDestinationLocalization;

      if (!destinationField) return;

      const matchingDestination = destinations.find(
        destination =>
          destination.type === searchDestination.type &&
          // @ts-ignore
          destination[destinationField] === searchDestination.text,
      );

      if (!matchingDestination) return;

      const localization = (matchingDestination.localizations || []).find(
        localization => localization?.language === language,
      );

      if (!localization) return;

      route.query.destination = JSON.stringify({
        destinationId: matchingDestination.expediaId as string,
        text: localization[destinationField] as string,
        ridcode: undefined,
        type: matchingDestination.type,
      });

      return route;
    };
  }
};

const suggestions = computed<AiSuggestion[]>(() => {
  if (!result.value?.searchDestination) return [];

  return (result.value?.searchDestination?.destinations || []).map(item => {
    const suggestion = {
      key: `${item?.type}-${item?.hotel?.id}`,
      props: item,
      value: '',
    } as AiSuggestion;

    switch (item?.type) {
      case SearchDestinationResultType.Country:
        suggestion.value = item.country as string;
        break;
      case SearchDestinationResultType.City:
        suggestion.value = item.city as string;
        break;
      case SearchDestinationResultType.Hotel:
        suggestion.value = item.hotel?.name as string;
        break;
    }

    return suggestion;
  });
});

const onSuggestionPicked = (suggestion: AiSuggestion) => {
  stop();

  const destination = suggestion.props as GQLSearchDestination;
  const hotel = destination.hotel;

  search.value = {
    destinationId: destination.expediaId as string,
    text: suggestion.value as string,
    ridcode:
      destination.type === SearchDestinationResultType.Hotel
        ? (hotel?.id as string)
        : undefined,
    type: destination.type as SearchDestinationResultType,
  };

  result.value = undefined;
};

const onFocus = () => {
  refetch({
    search: search.value.text as string,
  });
};

const onClear = () => {
  search.value = { text: '' };
  result.value = undefined;
  onDestinationsFetched();
  start();
};

const onDebounceInput = () => {
  if (search.value) {
    refetch({
      search: search.value.text as string,
    });
  } else {
    result.value = undefined;
    onDestinationsFetched();
  }
};
</script>
